/** @jsx jsx */
import { jsx } from "theme-ui";
import Layout from "../components/layout";
import SEO from "../components/seo";

const SectorLogo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path d="M233.89 393l-182-182a20.29 20.29 0 010-28.72c44.11-43.91 101.56-72.2 163.39-79.19C301 93.4 383.46 122.4 443.36 182.15l4.64 4.58 3.19 3.15a9.61 9.61 0 010 13.63l-2.5 2.5L261.56 393a19.56 19.56 0 01-27.67 0zm157.48-188.12l5.36-5.17a3.07 3.07 0 00-.18-4.59q-5.13-4.2-10.48-8.08a236.22 236.22 0 00-278.63 1.42q-3.84 2.85-7.58 5.87a4.24 4.24 0 00-.34 6.28l7 7 122.73 122.72 13.06 13.07a7.61 7.61 0 0010.79 0l16.17-16.4" />
  </svg>
);

export default props => (
  <Layout>
    <SEO
      title="Sector: The first MDX-based Digital Garden"
      description="You still haven't shipped your blog. Start gardening."
    />
    <nav
      sx={{
        display: "flex",
        background: "white",
        boxShadow: 1,
        height: "50px",
        paddingLeft: 4,
        paddingRight: 4
      }}
    >
      <SectorLogo height={50} /> <h1 sx={{ fontSize: 2 }}>Sector</h1>
    </nav>
    <div
      sx={{
        backgroundImage: `linear-gradient(43deg,#4158D033 0%,#C850C033 46%,#FFCC7033 100%)`,
        paddingTop: 5,
        paddingBottom: 5
      }}
    >
      <main
        sx={{
          variant: "styles.containerNarrow",
          boxShadow: 1,
          background: "white",
          borderRadius: 1,
          display: "flex",
          padding: 0
        }}
      >
        <div
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: 3
          }}
        >
          <p sx={{ fontSize: 5, fontWeight: 600, margin: 0, marginBottom: 1 }}>
            You still haven't shipped your blog
          </p>
          <p sx={{ fontSize: 3, margin: 0, marginBottom: [2, 6] }}>
            Sector is the world's first MDX-based digital garden.
          </p>
        </div>
        <div
          sx={{
            background: `url(${require("./lukas-blazek-GnvurwJsKaY-unsplash.jpg")})`,
            backgroundSize: "cover",
            borderTopRightRadius: 1,
            borderBottomRightRadius: 1,
            flex: 1
          }}
        />
      </main>
    </div>
  </Layout>
);
